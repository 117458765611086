.recharts-cartesian-grid-horizontal {
  line:nth-of-type(odd) {
    transform: scaleX(0.9);
    transform-origin: center;
  }
}

.recharts-tooltip-wrapper {
  z-index: 5;
  line-height: 1;
  ul li:nth-child(even) {
    margin-bottom: 8px;
  }
}
