:root {
  --text-color: #c0d6ea;
  --text-secondary-color: #536b84;
  --text-color-transparent-20: #c0d6ea33;
  --dark-bg: #062940;
  --light-bg: #0c3854;
  --icon-color: #b3dbff;
  --border-color: rgba(213, 235, 255, 0.25);
  --border-light: #2b4c62;
  --tw-logo-color: #1877f2;
  --tw-logo-color-transparent: #1877f232;
  --accent-color: #e9ff66;
  --green-color: #23ac6a;
  --border-radius: 8px;
  --overlay-color: #e4e7ec;
  --summary-bg: #f5f5f7;
  --collapsed-navigation-width: 72px;
  --open-navigation-width: 251px;
  --p-z-2: 900;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page {
  margin: 1cm 10cm;
}

.activeTile {
  background-color: rgba(24, 119, 242, 0.1);
}

.arrow-wrapper {
  font-weight: 400;
  font-size: .625rem;
}
.text-green {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity))
}

.text-dark-red {
  --tw-text-opacity: 1;
  color: rgb(198 62 36 / var(--tw-text-opacity));
}

.font-medium {
  font-weight: 500;
  font-size: 11px;
}

.center-text-in-div {
  display: grid;
  place-items: center;
}

.mantine-Paper-root{
  padding: 0.6rem;
}

.gray-background {
  background-color: rgb(242, 247, 254);
}

.recharts-text {
  fill: rgb(32, 34, 35);
}

#upgrade-modal-header {
  padding: 8px;
  display: flex;
  
  #upgrade-modal-close {
    margin-left: auto;
  }
}

.bg-green {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity))
}


.bg-logo\/10 {
  background-color: #1877f21a
}

.bg-logo\/15 {
  background-color: #1877f226
}

.bg-logo\/5 {
  background-color: #1877f20d
}

.bg-negative {
  --tw-bg-opacity: 1;
  background-color: rgb(143 56 86 / var(--tw-bg-opacity))
}

.bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity))
}