.card {
  --transition: 0.2s;

  height: fit-content;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 12px;
  transition: var(--transition);

  @media only screen and (max-width: 768px) {
    max-width: unset;
    width: 100%;
  }

  &:hover {
    box-shadow: 6px 6px 12px 0px rgba(6, 41, 64, 0.1);
  }

  .link {
    margin-top: 4px;
    font-size: 11px;
    color: #777;
    cursor: pointer;
    text-decoration: none;
    transition: var(--transition);
    line-height: 150.02%;

    &:hover {
      color: #222;
    }
  }

  .error {
    font-size: 11px;
    font-weight: 500;
    line-height: 150.02%;
    color: #f00044;
  }

  .card-main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 810px) and (min-width: 768px) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .brand-name {
    display: flex;
    align-items: center;
    gap: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 150.02%;
  }

  .brand-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
}

button.integrations-btn {
  background-color: var(--bg-color-1);
  color: var(--color);
  cursor: pointer;
  flex-grow: 1;
  min-height: 32px;
  height: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  outline: none;
  border: none;
  font-weight: 600;
  transition: var(--transition);
  width: 100px;

  &.primary {
    --bg-color-1: #e8f1fe;
    --bg-color-2: rgb(191 219 254);
    --color: #3b77f2;
  }

  &.secondary {
    --bg-color-1: #ffffff;
    --bg-color-2: rgb(243 244 246);
    --color: #5d5c5c;
    border: 1px solid rgba(93, 92, 92, 0.3);
    // transform: translateY(30%);
  }

  &:hover {
    background-color: var(--bg-color-2);
  }
}
